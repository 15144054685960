import React, { useState, ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import { Snackbar } from '@material-ui/core'
import { pathOr } from 'ramda'

import ERRORS from 'constants/errors'

interface ErrorMessage {
  messages: Array<{
    id: string,
    message: string;
  }>
}

interface Error {
  data: {
    message: Array<ErrorMessage>
  }
}

export type SetError = (error: Error) => void;

export const withErrorNotification = (Component: ComponentType<any>) => (props: any) => {
  const { t } = useTranslation()
  const [error, setError] = useState('')

  const handleError = (error: Error) => {
    const errorId = pathOr(
      '',
      ['data', 'message', 0, 'messages', 0, 'id'],
      error,
    )

    switch (errorId) {
    case ERRORS.INVALID_CREDENTIALS:
      setError(t('errors.invalid-credentials'))
      break;
    case ERRORS.ULOAD_SIZE_LIMIT:
      setError(t('validation.upload-size'))
      break
    default:
      setError(
        pathOr('', ['data', 'message', 0, 'messages', 0, 'message'], error)
      )
      break;
    }
  }

  const handleClose = () => {
    setError('')
  }

  return (
    <>
      <Component {...props} setError={handleError} />
      <Snackbar
        autoHideDuration={7000}
        open={!!error}
        message={error || ''}
        onClose={handleClose}
      />
    </>
  )
}
